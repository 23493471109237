import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/tree/forms',
        name: 'FormsObjectif',
        component: () => import('../views/FormsObjectif'),
        props: (route) => ({
          main_objective_id: route.query.main_objective_id,
          
        }),
      },
      {
        path: '/tree/dashboard',
        name: 'DashboardTree',
        component: () => import('../views/Dashboard'),
        props: (route) => ({
          main_objective_id: route.query.main_objective_id,
        }),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
