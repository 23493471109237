<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/tree/forms" class="nav-link"> <i class="nav-icon fas fa-edit"></i> Manage Objectives </router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/tree/dashboard" class="nav-link"><i class="nav-icon fas fa-columns"></i> OKR Tree View </router-link>
      </li>  
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'AppNavBar',
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">

</style>